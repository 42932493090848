import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OnboardBenefitComponent } from './shared/shared/onboard-benefit/onboard-benefit.component';
import { HealthCheckComponent } from './health-check/health-check.component';
import { ReinsurerLoginComponent } from './signin/reinsurer-login/reinsurer-login.component';
import { LoginComponent } from './signin/login/login.component';
import { LandingPageComponent } from './signin/landing-page/landing-page.component';

const routes: Routes = [
  {
    path: '',
    // loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
    component:LandingPageComponent
  },
  {
    path: 'auth',
    loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path: 'demo',
    loadChildren: () => import('./insurer-routing/demo-routing/demo-routing.module').then(m => m.DemoRoutingModule)
  },
  {
    path: 'axa-demo',
    loadChildren: () => import('./insurer-routing/axa-demo/axa-demo-routing.module').then(m => m.AxaDemoRoutingModule)
  },
  {
    path: 'insurancehouse',
    loadChildren: () => import('./insurer-routing/insurancehouse-routing/insurancehouse-routing.module').then(m => m.InsuranceHouseRoutingModule)
  },
  {
    path: 'abnic',
    loadChildren: () => import('./insurer-routing/abnic-routing/abnic-routing.module').then(m => m.AbnicRoutingModule)
  },
  {
    path: 'staging',
    loadChildren: () => import('./insurer-routing/staging-routing/staging-routing.module').then(m => m.StagingRoutingModule)
  },
  {
    path: 'orientunbtakaful',
    loadChildren: () => import('./insurer-routing/orientunbtakaful-routing/orientunbtakaful-routing.module').then(m => m.OrientUNBTakafulRoutingModule)
  },
  {
    path: 'qic',
    loadChildren: () => import('./insurer-routing/qic-routing/qic-routing.module').then(m => m.QicRoutingModule)
  },
  {
    path: 'dnic',
    loadChildren: () => import('./insurer-routing/dnic-routing/dnic-routing.module').then(m => m.DnicRoutingModule)
  },{
    path: 'newton',
    loadChildren: () => import('./insurer-routing/newton-routing/newton-routing.module').then(m => m.NewtonRoutingModule)
  },
  {
    path: 'orientinsurance',
    loadChildren: () => import('./insurer-routing/orientinsurance-routing/orientinsurance-routing.module').then(m => m.OrientInsuranceRoutingModule)
  },
  {
    path: 'qic-ri',
    loadChildren: () => import('./insurer-routing/qic-ri-routing/qic-ri-routing.module').then(m => m.QicRIRoutingModule)

  },
  {
    path: 'takafulemarat',
    loadChildren: () => import('./insurer-routing/takafulemarat-routing/takafulemarat-routing.module').then(m => m.TakafulemaratRoutingModule)

  },
  {
    path: 'onboard',
    component:OnboardBenefitComponent
  },
  {
    path:'health-check',
    component:HealthCheckComponent
  },
  {
    path:':reinsurer/login',
    component:ReinsurerLoginComponent
  },
  {
    path:':reinsurer/:insurer/login',
    component:LoginComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
