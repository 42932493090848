import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxSpinnerModule } from 'ngx-spinner';
import { ConfigManager } from './api-config/configmanager';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageLoaderComponent } from './spinner_css/page-loader/page-loader.component';
import { PremiumService } from './shared/shared/common-premium-roundup/premium-service';
import { HealthCheckComponent } from './health-check/health-check.component';
import { ReinsurerLoginComponent } from './signin/reinsurer-login/reinsurer-login.component';
import { LoginComponent } from './signin/login/login.component';
import { NotFoundComponent } from './signin/not-found/not-found.component';
import { LandingPageComponent } from './signin/landing-page/landing-page.component';
import { AuthenticationModule } from './authentication/authentication.module';


// export function initializeApp(configmanager: ConfigManager){
//   return () => configmanager.load();
// }

@NgModule({
  declarations: [
    AppComponent,
    PageLoaderComponent,
    HealthCheckComponent,
    NotFoundComponent,
    ReinsurerLoginComponent,
    LoginComponent,
    LandingPageComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AuthenticationModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      timeOut:1500,
    }),
  ],
  providers: [
    PremiumService
  //    ConfigManager,{
  //   provide: APP_INITIALIZER,
  //   useFactory: initializeApp,
  //   deps:[ConfigManager], multi:true
  // }
],
  bootstrap: [AppComponent]
})
export class AppModule { }
