import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { resolve } from "dns";
import * as XLSX from "xlsx";

@Component({
  selector: "app-onboard-benefit",
  templateUrl: "./onboard-benefit.component.html",
  styleUrls: ["./onboard-benefit.component.scss"],
})
export class OnboardBenefitComponent implements OnInit {
  @ViewChild("fileClass") fileClass: ElementRef;
  @Output() benefitsAndValues = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  parseBenefitValues(benefitValues) {
    let benefitValuePayloads = benefitValues.map((row) => {
      let benefitValuePayload = {};
      benefitValuePayload["value_header"] = row.portal_value? row.portal_value:row.tob_value ;
      benefitValuePayload["tob_value"] = row.tob_value ? row.tob_value:row.portal_value ;
      benefitValuePayload["factor"] = row.type.toLowerCase().trim() === "percentage" ? row.factor * 100 : row.factor;
      benefitValuePayload["factor_type"] = row.type;
      benefitValuePayload["is_default"] = row.is_default ?row.is_default: 0;

      return benefitValuePayload;
    });

    return benefitValuePayloads;
  }

  async readExcel(event) {
    const res: any = await this.fileToExcel(event);
    let benefitHeaderPayloads = res.headerSheetRows.map((row) => {
      let benefitHeaderPayload = { benefit_header_id: row.benefit_header_id };
      benefitHeaderPayload["benefit_header"] = row.hasOwnProperty("portal_header") ? row.portal_header : row.tob_header;
      benefitHeaderPayload["benefit_value"] = row.hasOwnProperty("portal_value") ? row.portal_value : row.tob_value;
      benefitHeaderPayload['benefit_link'] = row.hasOwnProperty('copay_dependency_parent_id') ? row.copay_dependency_parent_id : "";
      benefitHeaderPayload["benefit_type"] = row.hasOwnProperty("is_optional") ? (row.is_optional ? "Optional" : "Mandatory") : "Mandatory";
      benefitHeaderPayload["is_editable"] = row.hasOwnProperty("is_broker_portal_visible") ? row.is_broker_portal_visible : 0;
      benefitHeaderPayload['group_details'] = row.hasOwnProperty('group') ? row.group : "";
      benefitHeaderPayload["tob_header"] = row.hasOwnProperty("tob_header") ? row.tob_header : "";
      benefitHeaderPayload["tob_value"] = row.hasOwnProperty("tob_value") ? row.tob_value : "";
      benefitHeaderPayload['benefits_dependency_link'] = row.hasOwnProperty('benefit_dependency_parent_id') ? row.benefit_dependency_parent_id : "";
      benefitHeaderPayload['applicable_link'] = row.hasOwnProperty('applicable_rule') ? row.applicable_rule : "";
      benefitHeaderPayload['visibility_rule'] = row.hasOwnProperty('visibility_rule') ? row.visibility_rule : "";
      let matchedBenefitValues = [];
        matchedBenefitValues = res.valueSheetRows.filter((valueRow) => {
          return valueRow["benefit_header_id"] === benefitHeaderPayload["benefit_header_id"];
        });

        if (matchedBenefitValues.length == 0) {
          matchedBenefitValues = [{ portal_value: benefitHeaderPayload["benefit_value"],tob_value: benefitHeaderPayload["tob_value"], factor: 0, type: "percentage", is_default: 1 }];
          // throw "Bad request Exception"
        }
      const parsedBenefitValues = this.parseBenefitValues(matchedBenefitValues);
      benefitHeaderPayload["benefit_values"] = parsedBenefitValues;

      return benefitHeaderPayload;
    });

    if(benefitHeaderPayloads.length){
      this.benefitsAndValues.emit(benefitHeaderPayloads);
    }
    this.fileClass.nativeElement.value = "";
  }

  async fileToExcel(event) {
    return new Promise((resolve, reject) => {
      if (event.target.files.length > 0) {
        try {
          let benefitsFile = event.target.files[0];
          let fileReaderxlsx = new FileReader();
          fileReaderxlsx.readAsArrayBuffer(benefitsFile);
          fileReaderxlsx.onload = async (_e) => {
            let b: any = fileReaderxlsx.result;
            var xlsxdata = new Uint8Array(b);
            var array = new Array();
            for (var i = 0; i != xlsxdata.length; ++i) {
              array[i] = String.fromCharCode(xlsxdata[i]);
            }
            var bstr = array.join("");
            var workbook = XLSX.read(bstr, {
              type: "binary",
            });
            var first_sheet_name = workbook.SheetNames[0];
            var header_sheet = workbook.Sheets["benefit_header"];
            var value_sheet = workbook.Sheets["benefit_value"];

            let headerSheetRows = XLSX.utils.sheet_to_json(header_sheet);
            let valueSheetRows = XLSX.utils.sheet_to_json(value_sheet);

            resolve({ headerSheetRows, valueSheetRows });
          };
        } catch (exception) {
          reject(exception);
        }
      } else {
        reject("No File");
      }
    });
  }
}
