import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "src/app/api-service/api.service";
import { AuthServiceService } from "src/app/authentication/auth-service/auth-service.service";
import { API_ENDPOINTS } from "src/app/insurer-v1/constant/api.constant";
import { PermissionServiceService } from "src/app/permissions/permission-service.service";
import { SessionService } from "src/app/session-service/session.service";
import { API_ENDPOINTS_DISTRIBUTOR } from "src/assets/data-variable/api-constant";
import * as CryptoJS from 'crypto-js';
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  templateName:any = 'signIn';
  enableNotFound = false;
  loginForm: FormGroup;
  submitted = false;
  show = false;
  insurerName: any = "";
  insurerTheming:any;
  reinsurerName: any = "";
  secretKey = "012345";

  captchaCode: string;
  userResponse : string ='';
  isCaptchaValid: boolean;
  failed_count=0;
  validateCaptch:boolean=false;

  tokenFromUI = "93389a26699r89k3";
  request = "welcome";
  encrypted;
  decrypted;
  constructor(private spinner: NgxSpinnerService,private permissions:PermissionServiceService, private toaster: ToastrService, private apiService: ApiService, private router: Router, private sessionService: SessionService, public fb: FormBuilder, private authService: AuthServiceService, private activateRoute: ActivatedRoute) {}

  async ngOnInit(): Promise<void> {
    sessionStorage.clear();
    this.loginForm = this.fb.group({
      email_id: ["", [Validators.required, Validators.email, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]],
      password: ["", [Validators.required]],
      captchaText: [''],

    });

    this.activateRoute.params.subscribe((params) => {
      this.reinsurerName = params?.["reinsurer"] || "";
      this.insurerName = params?.["insurer"] || "";
    });

    await this.verifyPath();
    const pathName = location.pathname.split("/");
    if(pathName.includes('orientinsurance')){
        this.insurerTheming = 'orientinsurance'
    }else{
        this.insurerTheming = 'default'
    }
    this.sessionService.set('insurerTheming',this.insurerTheming)

  }

  get formControls() {
    return this.loginForm.controls;
  }

  async verifyPath() {
    try {
      let params = {
        reinsurerName: this.reinsurerName,
        insurerName: this.insurerName,
      };
      this.sessionService.set("insurerURL", this.insurerName);
      this.sessionService.set("reinsurerName", this.reinsurerName);
      let response = await this.apiService.postApiCall(`auth/${API_ENDPOINTS.checkInsurerAndReinsurePath}`, params);

    } catch (er) {
      if (er.response.data.statusCode == 404) {
        this.enableNotFound = true;
      }
    }
  }

  encryptUsingAES256(req) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      req, _key, {
        keySize: 32,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
    this.encrypted = encrypted.toString();
    return this.encrypted;
  }

  async login() {
    try {
      this.spinner.show();
      this.submitted = true;
      let captchaValid=false;
      if(this.validateCaptch)
      {
      captchaValid=await this.validateCaptcha();
      if(!captchaValid)
      {
        this.toaster.error("Invalid captcha. Please try again.");
        return
      }
      }
      if (!this.loginForm.valid) {
        return;
      }
      this.loginForm.value["insurerURL"] = this.insurerName;
      this.loginForm.value["reinsurerName"] = this.reinsurerName;
      const cipherText=this.encryptUsingAES256(JSON.stringify(this.loginForm.value));
      let results = await this.apiService.postApiCall(`${API_ENDPOINTS_DISTRIBUTOR.insurerBasedLogin}`, {credentials:cipherText });
      if (results.statusCode==200)
      {
        this.failed_count=0;
      }
      if (!results) {
        this.toaster.error("Something went wrong");
        return;
      }
      const permissionsResponse = await this.apiService.getApiCall(`${API_ENDPOINTS_DISTRIBUTOR.getAllPermissions}/${results.response.role}`, {});
      // if (permissionsResponse) {
        this.permissions.userPermissions= permissionsResponse.response;
        this.sessionService.set('permissions',JSON.stringify(permissionsResponse.response));
      // }
      // return
      // if(results.response.role == 'underWriter'){
      //   results.response.role = 'insurer'
      // }

      this.sessionService.set("token", results.response.token);
      this.sessionService.set("role", results.response.role);
      this.sessionService.set("user_id", results.response.user_id);
      this.sessionService.set("insurerURL", results.response.insurerURL);
      this.sessionService.set("brokerCompanyId", results.response.broker_company_id);
      this.sessionService.set("reinsurerName", results.response.reInsurerUrl);
      this.sessionService.set("insurerName", this.insurerName);
      this.sessionService.set("reinsurerShortForm", results.response.reinsurerShortForm);
      if(results.response.passwordExpiry) {
        this.sessionService.set("passwordExpiry", results.response.passwordExpiry)
        this.router.navigateByUrl(`/auth/reset-password`);
        return
      };
      if (results.response.role == "distributor") {
        this.router.navigateByUrl(`${results.response.insurerURL}/distributor-v1/main`);
      } else if (results.response.role == "customer") {
        this.router.navigateByUrl(`${results.response.insurerURL}/customer-v1/home`);
      } else if (results.response.role == "reinsurer") {
        this.router.navigateByUrl(`${results.response.insurerURL}/reinsurer-v1`);
      } else if (results.response.role == "insurer" || results.response.role == "underwriter"  || results.response.role == "salesaccess"  || results.response.role == "operational")  {
        this.router.navigateByUrl(`${results.response.insurerURL}/${results.response.role}-v1/dashboard`);
      }

      this.toaster.success("Logged in successfully");


  }catch (error) {
      if (error.response.data["statusCode"] == 404)  {
        this.toaster.error(error.response.data.message);
        return;
      }
      else if (error.response.data["statusCode"] == 403) {
        this.failed_count+=1;
        if(this.failed_count>=3)
        {
          this.validateCaptch=true;
          this.captchaCode =await this.generateCaptcha(5);

        }
      }
      //  else if (error.response.data['statusCode'] == 401) {
      //   this.toaster.error(error.response.data.message)
      //   return
      // }
      // else if (error.response.data['statusCode'] == 400) {
      //   this.toaster.error("Invalid credentials")
      //   return
      // }
    } finally {
      this.spinner.hide();
    }
  }
  async validateCaptcha() {
    this.isCaptchaValid = false;
    if (this.loginForm.value.captchaText.toLowerCase() === this.captchaCode.toLowerCase()) {
      // Captcha matched, proceed with the login logic
      this.isCaptchaValid = true;
      // Add your login logic here
    } else {
      // Captcha did not match
      this.isCaptchaValid = false;
      this.captchaCode =await this.generateCaptcha(5); // Generate a new captcha
    }
    this.userResponse = '';
    return this.isCaptchaValid;
  }

  async generateCaptcha(length: number){
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
  }

  async openForgotPasswordTemplates(){
    this.templateName = 'forgotPassword';
  }
  async passwordUpdatedNotifications(event){
    this.templateName = 'signIn';
  }
}
