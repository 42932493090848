import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/api-service/api.service';
import { API_ENDPOINTS } from 'src/app/insurer-v1/constant/api.constant';
import { SessionService } from 'src/app/session-service/session.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  @Output() passwordUpdated: EventEmitter<any> = new EventEmitter();
  forgotPasswordForm: FormGroup;
  submitted = false;
  insurerUrl:String;
  reInsurerName:String;
  constructor(public fb: FormBuilder,private toaster: ToastrService,private spinner: NgxSpinnerService,private sessionService:SessionService,private apiService: ApiService) { }

  ngOnInit(): void {
    this.insurerUrl = this.sessionService.get("insurerURL");
    this.reInsurerName = this.sessionService.get("reinsurerName");
    this.forgotPasswordForm = this.fb.group({
      email_id: ["", [Validators.required, Validators.email, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]],
    });
  }

  get formControls() {
    return this.forgotPasswordForm.controls;
  }


  async submitPassword(){
    try {
      this.spinner.show();
      this.submitted = true;
      if (!this.forgotPasswordForm.valid) {
        return;
      }
      let params ={
        emailId:this.forgotPasswordForm.value.email_id,
        insurerUrl:this.insurerUrl,
        reInsurerName:this.reInsurerName
      }

      let response = await this.apiService.postApiCall(`auth/${API_ENDPOINTS.forgotPassword}`, params);
      if(response?.statusCode == 200){
        this.toaster.success("New password sent successfully");
        this.passwordEmit();
      }
    }catch(exception){
      this.toaster.error("Something went wrong while password resetting")
    }finally{
      this.spinner.hide();
    }
  }

  passwordEmit(){
    this.passwordUpdated.emit('true')
  }
}
