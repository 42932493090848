import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/api-service/api.service';
import { SessionService } from 'src/app/session-service/session.service';
import { API_ENDPOINTS_DISTRIBUTOR } from 'src/assets/data-variable/api-constant';
// import { OmanInsuranceService } from '../service/oman-insurance.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  matchPassword = false;
  @Input() hideCloseIcon:any = 'show';
  show = false;
  newPasswordShow=false;
  @Input() openFrom = "";
  confirmPasswordShow=false;
  changePasswordForm: FormGroup;
  submitted: boolean = false;
  insurerUrl: any;
  role: any;
  isValidationError: boolean = false;
  constructor(
    private toster:ToastrService,
    private router:Router,  public fb: FormBuilder,  private modalService: NgbModal,private apiService:ApiService,private sessionService:SessionService) { }

  ngOnInit(): void {
    this.changePasswordForm = this.fb.group({
      current_password: ['',[Validators.required]],
      new_password: ['',[Validators.required]],
      confirm_password: ['',[Validators.required]]
    });

  }

  getSessionData() {
    this.insurerUrl = this.sessionService.get("insurerURL");
    this.role = this.sessionService.get("role");
  }

  get formControls() {
    return this.changePasswordForm.controls
  }

  async confirmPassword(){
    try{
      this.getSessionData();
    this.submitted = true;
    if (!this.changePasswordForm.valid) {
      return;
    }
    if (this.changePasswordForm.value.new_password !== this.changePasswordForm.value.confirm_password) {
      this.matchPassword = true;
      return;
    }
    this.matchPassword = false;
    if(this.newPasswordValidation()) {
      return;
    }
    if(this.changePasswordForm.value.new_password === this.changePasswordForm.value.current_password) {
      this.toster.error("The new password cannot be the same as the current password");
      return;
    }
    let results = await this.apiService.putApiCall(`${this.insurerUrl}/${this.role}/${API_ENDPOINTS_DISTRIBUTOR.changePassword}`, this.changePasswordForm.value);
    if(results.status === 401) {
      this.toster.error(results.message);
      return;
    }
    if(!results){
      this.toster.error("Something went wrong");
      return;
    }
    this.toster.success("Password updated successfully")
    if(this.role == 'reinsurer'){
      let reinsurerShortForm = this.sessionService.get("reinsurerShortForm");
      this.router.navigateByUrl(`${reinsurerShortForm}/login`);
    }else{
      this.router.navigateByUrl(`/${this.insurerUrl}/login`);
    }
    this.modalService.dismissAll()

  }catch(error){
    if(error.response.status == 400){
      this.toster.error(error.response.data.message)
      return
    }else if(error.response.status == 401){
      this.toster.error(error.response.data.message)
      return
    }
  }
  }

  closePasswordPopup(){
    this.modalService.dismissAll();
  }

  newPasswordValidation() {
    function validatePassword(password: any) { 
      const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/; 
      return regex.test(password); 
    }
    const newPassword = this.changePasswordForm.value.new_password;
    if (!validatePassword(newPassword)) { 
      return this.isValidationError = true;
    }
    return this.isValidationError = false;
  }

  clearPasswordErrorMessage() {
    const isPasswordEntered = this.changePasswordForm.value.new_password
    if(isPasswordEntered) {
      this.isValidationError = false;
      return;
    }
  }
}
