import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/api-service/api.service';
import { AuthServiceService } from 'src/app/authentication/auth-service/auth-service.service';
import { API_ENDPOINTS } from 'src/app/insurer-v1/constant/api.constant';
import { SessionService } from 'src/app/session-service/session.service';
import { routePathForPeakre } from 'src/assets/data-variable/mock-data';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-reinsurer-login',
  templateUrl: './reinsurer-login.component.html',
  styleUrls: ['./reinsurer-login.component.scss']
})
export class ReinsurerLoginComponent implements OnInit {
  templateName:any = 'signIn';
  loginForm: FormGroup;
  submitted = false;
  show = false;
  reinsurerName ='';
  insurerName: any;
  enableNotFound: boolean = false;
  secretKey = "012345";

  captchaCode: string;
  userResponse : string ='';
  isCaptchaValid: boolean;
  failed_count=0;
  validateCaptch:boolean=false;

  tokenFromUI = "93389a26699r89k3";
  request = "welcome";
  encrypted;
  decrypted;
  constructor(private spinner: NgxSpinnerService, private toaster: ToastrService,private apiService:ApiService,
    private router: Router, private sessionService: SessionService, public fb: FormBuilder, private authService: AuthServiceService,private activateRoute:ActivatedRoute) { }

  async ngOnInit(): Promise<void> {
    this.loginForm = this.fb.group({
      email_id: ['', [Validators.required, Validators.email,Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]],
      password: ['', [Validators.required]],
      captchaText: [''],
    });
    this.captchaCode =await this.generateCaptcha(5);

    this.activateRoute.params.subscribe(params => {
     this.reinsurerName = params?.['reinsurer'] || '';
     this.insurerName = params?.['insurer'] || '';
    });

    if(this.reinsurerName == 'insurancehouse' || this.reinsurerName == 'abnic'  || this.reinsurerName == 'dnic' || this.reinsurerName == 'newton' || this.reinsurerName == 'axa-demo'|| this.reinsurerName == 'qic' || this.reinsurerName == 'takafulemarat' || this.reinsurerName == 'orientunbtakaful' || this.reinsurerName =='orientinsurance' || this.reinsurerName== 'staging-test'){
      this.router.navigateByUrl(`${routePathForPeakre[this.reinsurerName]}/${this.reinsurerName}/login`);
      return
    }
    await this.verifyPath();
  }

  get formControls() {
    return this.loginForm.controls
  }

  async verifyPath(){
    try{
      let params ={
        reinsurerName:this.reinsurerName,
        insurerName:this.insurerName
      }
      this.sessionService.set("insurerURL", this.insurerName);
      this.sessionService.set("reinsurerName", this.reinsurerName);
      let response = await this.apiService.postApiCall(`auth/${API_ENDPOINTS.checkInsurerAndReinsurePath}`,params);

      if(response){
        this.enableNotFound = false;
      }
    }catch(er){

      if(er.response.data.statusCode == 404){
        this.enableNotFound  = true;
      }
    }
  }

  encryptUsingAES256(req) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      req, _key, {
        keySize: 32,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
    this.encrypted = encrypted.toString();
    return this.encrypted;
  }

  async login() {
    try {
      this.spinner.show()
      this.submitted = true;
      let captchaValid=false;
      if(this.validateCaptch)
      {
      captchaValid=await this.validateCaptcha();
      if(!captchaValid)
      {
        this.toaster.error("Invalid captcha. Please try again.");
        return
      }
      }
      if (!this.loginForm.valid) {
        return;
      }
      this.loginForm.value['reinsurerName'] = this.reinsurerName;
      const cipherText=this.encryptUsingAES256(JSON.stringify(this.loginForm.value));
      let results: any = await this.authService.login({credentials:cipherText });
      if (results.statusCode==200)
      {
        this.failed_count=0;
      }
      if (!results) {
        this.toaster.error("Something went wrong");
        return
      }
      this.sessionService.set("token", results.data.token)
      this.sessionService.set("role", results.data.role)
      this.sessionService.set("user_id", results.data.userId)
      this.sessionService.set("insurerURL", results.data.company)
      this.sessionService.set("username", results.data.user_name)
      this.sessionService.set("brokerCompanyId", "")
      this.sessionService.set('reinsurerName',results.data.reInsurerUrl);
      this.sessionService.set('reinsurerShortForm',results.data.reinsurerShortForm);
      if(results.data.passwordExpiry) {
        this.sessionService.set("passwordExpiry", results.data.passwordExpiry)
        this.router.navigateByUrl(`/auth/reset-password`);
        return;
      };

      if(results.data.role == 'distributor'){
        this.router.navigateByUrl(`/${results.data.company}/distributor-v1/main`)
      }else if(results.data.role == 'customer'){
        this.router.navigateByUrl(`/${results.data.company}/customer-v1/home`)
      }else if(results.data.role == 'reinsurer'){
        this.router.navigateByUrl(`/${results.data.company}/reinsurer-v1`)
      }
      else if(results.data.role == 'insurer'){
        this.router.navigateByUrl(`/${results.data.company}/insurer-v1/dashboard`)
      }


      this.toaster.success("Logged in successfully");


  }catch (error) {
    if (error.response.data["statusCode"] == 403) {
      this.failed_count+=1;
      if(this.failed_count>=3)
      {
        this.validateCaptch=true;
        this.captchaCode =await this.generateCaptcha(5);

      }
    }
        // if (error.response.status == 400) {
      //   this.toaster.error(error.response.data.message)
      //   return
      // } else if (error.response.status == 401) {
      //   this.toaster.error(error.response.data.message)
      //   return
      // }
    } finally {
      this.spinner.hide()
    }
  }
  async validateCaptcha() {
    this.isCaptchaValid = false;
     if (this.loginForm.value.captchaText.toLowerCase() === this.captchaCode.toLowerCase()) {
      // Captcha matched, proceed with the login logic
      this.isCaptchaValid = true;
      // Add your login logic here
    } else {
      // Captcha did not match
      this.isCaptchaValid = false;
      this.captchaCode =await this.generateCaptcha(5); // Generate a new captcha
    }
    this.userResponse = '';
    return this.isCaptchaValid;
  }

  async generateCaptcha(length: number){
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
  }

  async openForgotPasswordTemplates(){
    this.templateName = 'forgotPassword';
  }
  async passwordUpdatedNotifications(event){
    this.templateName = 'signIn';
  }

}
