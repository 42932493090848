import { premiumRoundUp } from "./premium-interface";

export class insuranceHousepremiumCalculation implements premiumRoundUp {
    changePremiumRoundUp(premium:number) {
     var updatedPremium = Math.round(premium);
      return Number(updatedPremium);
    }

    changePremiumDisplayRoundUp(premium:number){
      var updatedPremium = Math.floor(premium);
      return Number(updatedPremium);
    }
  }