import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AxiosInstance } from "axios";
import { ToastrService } from "ngx-toastr";
import { SessionService } from "src/app/session-service/session.service";
@Injectable({
  providedIn: "root",
})
export class BaseInterceptor {
  constructor(private router: Router, private sessionService: SessionService, private toasterService: ToastrService) {}

  interceptor = (axiosInstance: AxiosInstance) => {
    axiosInstance.interceptors.request.use(
      (request) => {
        const insurerURL = this.sessionService?.get("insurerName");
        const reinsurerName = this.sessionService?.get("reinsurerName");
        const token = this.sessionService?.get("token");
        const clientCode = this.sessionService?.get("clientCode");
        request.headers["Authorization"] = `Bearer ${token}`;
        request.headers["client_code"] = `${clientCode}`;
        request.headers["insurerURL"] = `${insurerURL}`;
        request.headers["reinsurerName"] = `${reinsurerName}`;
        return request;
      },
      (error) => Promise.reject(error)
    );

    axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
       let insurerName = this.sessionService.get('insurerName')
       let reinsurerShortForm =  this.sessionService.get('reinsurerShortForm');
        if (error.response.status === 401) {
          this.router.navigateByUrl(`/`);
        } else if (error.response?.status === 403) {
          this.toasterService.error(error?.response?.data?.message || "Forbidden");
        } else if (error.response?.status === 404) {
          this.toasterService.error(error?.response?.data?.message || "Data not found");
        }
        //  else if (error.response?.status === 500) {
        //   this.toasterService.error(error?.response?.data?.message || "Something went wrong");
        // }
        return Promise.reject(error);
      }
    );
  };
}
