import { premiumCalculation } from "./common-premiumroundup-calculation";
import { insuranceHousepremiumCalculation } from "./insurancehouse-premiumroundup-calculation";

export class PremiumService {
    premiumRoundUp(insurer: any, premium,optionalParam = null) {
      let updatedPremium;
      if ((insurer === "insurancehouse" || insurer === 'abnic' || insurer === "takafulemarat") && optionalParam == 'gwpRoundOff') {
        updatedPremium = new insuranceHousepremiumCalculation();
        return updatedPremium.changePremiumRoundUp(premium);
      }else if(insurer === "takafulemarat" && optionalParam == 'displayRoundOff'){
        updatedPremium = new insuranceHousepremiumCalculation(); 
        return updatedPremium.changePremiumDisplayRoundUp(premium);
      }else {
        updatedPremium = new premiumCalculation();
        return updatedPremium.changePremiumRoundUp(premium);
      }
    }
  }