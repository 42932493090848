import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SessionService } from 'src/app/session-service/session.service';
import { AuthServiceService } from '../auth-service/auth-service.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  show = false;
  reinsurerName ='';
  constructor(private spinner: NgxSpinnerService, private toaster: ToastrService,
    private router: Router, private sessionService: SessionService, public fb: FormBuilder, private authService: AuthServiceService,private activateRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email_id: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      password: ['', [Validators.required]],
    });

    this.activateRoute.params.subscribe(params => {
      this.reinsurerName = params['reinsurer'];
     });
  }

  get formControls() {
    return this.loginForm.controls
  }

  async login() {
    try {
      this.spinner.show()
      this.submitted = true;
      if (!this.loginForm.valid) {
        return;
      }
      this.loginForm.value['reinsurerName'] = this.reinsurerName;
      let results: any = await this.authService.login(this.loginForm.value);
    
      if (!results) {
        this.toaster.error("Something went wrong");
        return
      }

      if(results.data.role == 'distributor'){
        this.router.navigateByUrl(`/${results.data.company}/distributor-v1/main`)
      }else if(results.data.role == 'customer'){
        this.router.navigateByUrl(`/${results.data.company}/customer-v1/home`)
      }else if(results.data.role == 'reinsurer'){
        this.router.navigateByUrl(`/${results.data.company}/reinsurer-v1`)
      }
      else if(results.data.role == 'insurer' || results.data.role == 'underwriter'){
        this.router.navigateByUrl(`/${results.data.company}/insurer-v1/dashboard`)
      }
     
      this.sessionService.set("token", results.data.token)
      this.sessionService.set("role", results.data.role)
      this.sessionService.set("user_id", results.data.user_id)
      this.sessionService.set("insurerURL", results.data.company)
      this.sessionService.set("username", results.data.user_name)
      this.sessionService.set("brokerCompanyId", "")

      this.toaster.success("Logged in successfully");

    } catch (error) {
      if (error.response.status == 400) {
        this.toaster.error(error.response.data.message)
        return
      } else if (error.response.status == 401) {
        this.toaster.error(error.response.data.message)
        return
      }
    } finally {
      this.spinner.hide()
    }
  }
}
